.nav {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 2rem 3rem 0rem 6rem;
    position: absolute;
    width: 100%;
    height: 5rem;
    left: 0;
    z-index: 1;
}

.nav div {
    align-items: center;
    display: flex;
    column-gap: 2rem;
    height: 100%;
}

.logo {
    height: 100%;
    cursor: pointer;
}

@media screen and (max-width: 767px) {
   .nav {
    padding: 1rem 1rem;
   }
}

@media screen and (max-width: 450px) {
    .nav button {
        visibility: hidden;
    }
}